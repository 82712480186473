import React, { Component } from 'react';
import { Grid, Cell } from 'react-mdl';
import Education from './education';
import Experience from './experience';
import Skills from './skills';

class Resume extends Component {
  render() {
    return (
      <div>
        <Grid>
          <Cell col={4}>
            <div>
              <img
                className='avatar-img-resume'
                src='../Img/avatar.png'
                alt='avatar'
              />
            </div>

            <h2 style={{ paddingTop: '0em' }}>Bishal Wagle</h2>
            <h4 style={{ color: 'grey' }}> Objective </h4>
            <hr style={{ borderTop: '3px solid #833fb2', width: '100%' }} />
            <p>
              Versatile developer who is eager to apply and further my knowledge
              in a software development position.
            </p>
            <hr style={{ borderTop: '3px solid #833fb2', width: '50%' }} />
            <h5>Address</h5>
            <p>9725 Oak Reserve Ln, Elk Grove, 95758</p>
            <hr style={{ borderTop: '3px solid #833fb2', width: '50%' }} />
            <h5>Phone</h5>
            <p>(510)-374-9554</p>
            <hr style={{ borderTop: '3px solid #833fb2', width: '50%' }} />
            <h5>Email</h5>
            <p>bishalw@gmail.com</p>
            <hr style={{ borderTop: '3px solid #833fb2', width: '50%' }} />
            <h5>Web</h5>
            <a href='https//:github.com/bishalw'>Github</a>
            <hr style={{ borderTop: '3px solid #833fb2', width: '100%' }} />

            <h2>Skills</h2>
            <Skills skill='javascript' progress={80} />
            <Skills skill='HTML/CSS' progress={80} />
            <Skills skill='NodeJS' progress={50} />
            <Skills skill='React' progress={70} />
            <Skills skill='Express JS' progress={60} />
            <Skills skill='Node' progress={60} />
            <Skills skill='Java' progress={75} />
          </Cell>
          <Cell className='resume-right-col' col={8}>
            <h2>Education</h2>
            <Education
              startYear={2016}
              endYear={2019}
              schoolName='San Jose State University'
              schoolDescription='B.S Software Engineering'
            />
            <Education
              startYear={2013}
              endYear={2016}
              schoolName='Berkeley City College'
              schoolDescription='A.A Degree Mathematics'
            />
            <hr style={{ borderTop: '3px solid #e22947' }} />
            <h2>Experience</h2>
            <Experience
              startYear={'January'}
              endYear={'February 2020'}
              jobName='User Login'
              jobDescription='User account creation and login feature'
              jobDescriptionList={[
                'Implemented account creation and authorization from scratch using Vanilla JS/HTML/CSS',
                'Utilized JS event listener and regex field validity '
              ]}
            />
            <Experience
              startYear={'December'}
              endYear={'February 2020'}
              jobName='Connect App'
              jobDescription='Connecting developers using social media hub'
              jobDescriptionList={[
                'Designed and implemented the back-end server using the MERN Stack',
                'Implemented RESTful APIs to perform CRUD operations',
                'Implemented JWT along with custom authorization for user encryption'
              ]}
            />
            <Experience
              startYear={'December'}
              endYear={'January 2020'}
              jobName='Expense Tracker'
              jobDescription='Group music queue application that allows users to join part and add music to queue'
              jobDescriptionList={[
                'Implemented local session storage to cache data',
                'Wrote logic for calculating and updating income and balance',
                'Added transactions to DOM List '
              ]}
            />
            <Experience
              startYear={'January'}
              endYear={'December 2018'}
              jobName='Unirooms'
              jobDescription=' Application for finding optimal roommates based on profiles
              '
              jobDescriptionList={[
                ' Developed cross platform application using React-Native, ES6 and Redux',
                'Implemented the Project using Agile methodology',
                'Integrated Firebase to expedite model and content storage'
              ]}
            />
            <Experience
              startYear={'September'}
              endYear={'December 2017'}
              jobName='Q Loop'
              jobDescription='Multiple user group music queue application '
              jobDescriptionList={[
                'Technology used: React.JS, MongoDB, Python, Restful API',
                'Implemented login',
                'Integrated with YouTube API',
                'Created React components for User, Login and Homepage'
              ]}
            />
          </Cell>
        </Grid>
      </div>
    );
  }
}

export default Resume;
