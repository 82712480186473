import React, { Component } from 'react';
import { Grid, Cell } from 'react-mdl';
import Particles from 'react-particles-js';
import {
  CardText,
  CardTitle,
  CardActions,
  Card,
  Button,
  CardMenu,
  IconButton
} from 'react-mdl';

// const particleOpt = {
//   particles: {
//     number: {
//       value: 250,
//       density: {
//         enable: true,
//         value_area: 150
//       }
//     }
//   }
// };

class Landing extends Component {
  render() {
    return (
      <div>
        <div style={{ width: '100%', margin: 'auto' }}>
          <div className='landing-grid'>
            <div className='backgroundP'>
              <div className='Foreground'>
                <div>
                  <img
                    src='/Img/avatar.png'
                    alt='avatar'
                    className='avatar-img'
                  />
                </div>
                <Particles></Particles>

                <Card shadow={0} className='landing-page-card'>
                  <CardText>
                    <div className='landing-page-name'> Bishal Wagle </div>
                    <div className='landing-page-title'>
                      {' '}
                      Software Engineer{' '}
                    </div>

                    <hr />

                    <div className='landing-page-skills'>
                      HTML/CSS | Bootstrap | JavaScript | React | React Native |
                      NodeJS | Express | MongoDB
                    </div>
                  </CardText>
                </Card>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Landing;
